import { InputLabel, MenuItem, TextField } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { parse } from "date-fns";
import useStyles from "./styles";
import { useContext, useEffect } from "react";
import { AuthContext } from "context/context";

const SelectSection = ({
  options = [],
  change,
  data,
  error,
  disableSelects,
}) => {
  const classes = useStyles();
  const { setTypeEnd } = useContext(AuthContext);

  useEffect(() => {
    if (data?.tipend) {
      setTypeEnd(data.tipend);
    }
  }, [data.tipend, setTypeEnd]);

  const fechaInicial =
    typeof data.fechainicial === "string"
      ? parse(data.fechainicial, "dd/MM/yyyy", new Date())
      : data.fechainicial;

  const handleSelectChange = (event) => {
    const selectedOption = options.find(
      (option) => option.idEndoso === event.target.value
    );

    change({
      target: {
        name: "tipend",
        value: {
          idEndoso: selectedOption.idEndoso,
          descEndoso: selectedOption.descEndoso,
        },
      },
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.sections}>
        <InputLabel htmlFor="dateEndoso" className={classes.label}>
          Fecha del endoso
        </InputLabel>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            id="dateEndoso"
            size="small"
            inputVariant="outlined"
            format={"dd-MM-yyyy"}
            value={fechaInicial || null}
            error={error.date ? true : false}
            helperText={error.date || ""}
            disabled={disableSelects}
            onChange={(e) => {
              change({ target: { name: "fechainicial", value: e } });
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.sections}>
        <InputLabel htmlFor="select" className={classes.label}>
          Tipo de endoso
        </InputLabel>
        <TextField
          id="select"
          name="tipend"
          variant="outlined"
          size="small"
          select
          error={error.select ? true : false}
          helperText={error.select || ""}
          onChange={handleSelectChange}
          disabled={disableSelects}
          value={data.tipend || ""}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.idEndoso}>
              {option.idEndoso + " " + option.descEndoso}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </div>
  );
};

export default SelectSection;
