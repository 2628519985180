import { Typography } from "@material-ui/core";
import { Fragment, useContext } from "react";
import { asyncForEach } from "..";
import { AltaContext, EndosoContext } from "pages/endoso/context";
import { endoso } from "../../../../services";
import { AuthContext } from "context/context";

const ErrorComponent = ({ errors, persons }) => {
  return (
    <Fragment>
      {persons.length === 0 ? (
        <Typography>
          Para generar el endoso se debe agregar al menos un asegurado nuevo
        </Typography>
      ) :
        <Typography>
          Las siguientes personas no pudieron ser agregadas:
        </Typography>
      }
      {errors?.map((person, index) => (
        <div key={index}>
          {person?.segundoNombre ? (
            <Typography>{`${person?.primerNombre ?? ''} ${person?.segundoNombre ?? ''} ${person?.apellidoPaterno ?? ""
              } ${person?.apellidoMaterno ?? ''}: ${person?.error?.replace(
                /null\s/g,
                ""
              )}`}</Typography>
          ) : (
            <Typography>{`${person?.primerNombre ?? ''} ${person?.apellidoPaterno ?? ''} ${person?.apellidoMaterno ?? ""
              }: ${person?.error?.replace(/null\s/g, "")}`}</Typography>
          )}

          <br />
        </div>
      ))}
    </Fragment>
  );
};

const errorText = {
  emply: "Para generar el endoso se debe agregar al menos un asegurado nuevo",
  default: "Ocurrio un error al guardar el asegurado",
  invalidType:
    "El archivo no tiene una extensión permitida, por favor verifique.",
};

export const useRulesBy12 = () => {
  const { setBadPersons, file } = useContext(AltaContext);
  const { setShowContinue, setPersons, persons } = useContext(AuthContext);
  const { createMessage, setShowButtons, showButtons } = useContext(EndosoContext)

  /* methods */
  const rulesByTwelve = async (
    solicitud,
    data,
    setMessage,
    setShowMessage,
    formDta,
    setAlertData,
    dataSend) => {
    let alertShown = false;

    if (!file) {
      const saveEndoso = async (changeType = false) => {
        const removedPersons = [];
        const personsCopy = Array.isArray(persons) ? [...persons] : [];
        let personsForSave = persons;

        const altasFunction = async (person, index) => {
          const body = {
            tipoIdentificador: "0",
            primerNombre: person.primerNombre,
            segundoNombre: person.segundoNombre || null,
            nombre: person.nombre,
            apPaterno: person.apellidoPaterno,
            apMaterno: person.apellidoMaterno,
            fchNacimiento: person.fechainicial,
            sexo: person.sexo,
            rfc: null,
            razonSocial: "FISICA",
            fechaNacimiento: true,
            oficina: data.oficina,
            ramo: data.ramo,
            poliza: data.poliza,
            solicitud: formDta.noSolicitud,
            categoria: `${changeType && person.categoria === 2 ? 3 : person.categoria}`,
          };

          const checkResp = await endoso.carga605(body);
          if (checkResp?.data?.codigo === 1) {
            personsForSave[index] = {
              ...person,
              newCategory: checkResp.data?.categoria,
            };
            if (checkResp.data.mensaje && checkResp.data.mensaje !== '' && !alertShown) {
              createMessage({
                text: checkResp?.data?.mensaje,
                variant: "info",
                action: true,
              });
              alertShown = true;
            }
            return 1;
          } else {
            removedPersons.push({
              ...persons[index],
              pos: index,
              error: checkResp?.data?.mensaje,
            });
            return 0;
          }
        };

        const responseSaves = await asyncForEach(personsCopy, altasFunction);

        const filteredPersons = personsForSave
          .map((person, index) => {
            const removedPerson = removedPersons.find((item) => item.pos === index);
            return removedPerson ? null : person;
          })
          .filter((person) => person !== null);

        if (responseSaves.find((save) => save === 1)) {
          const diferenciaResponse = await endoso.diferencia({
            solicitud: formDta.noSolicitud,
          });

          if (removedPersons.length > 0) {
            setMessage({
              text: <ErrorComponent errors={removedPersons} persons={persons} />,
              severity: "warning",
            });
            setShowMessage(true);
          }

          if (diferenciaResponse?.data?.codigo === "1") {
            setPersons(
              filteredPersons.map((person) => ({
                ...person,
                importeActual: diferenciaResponse?.data.importeActual,
                importeAnterior: diferenciaResponse?.data.importeAnterior,
                importeDiferencia: diferenciaResponse?.data.importeDiferencia,
              }))
            );

            const clausulaResponse = await endoso.clausula({
              solicitud: formDta.noSolicitud,
            });
            if (clausulaResponse?.data?.codigo === "1") {
              setShowButtons({ ...showButtons, infoTable: true });
            } else {
              setMessage({
                text: clausulaResponse?.data?.mensaje || "Ocurrio un error de conexion",
                severity: "warning",
              });
              setShowMessage(true);
            }
          } else {
            setMessage({
              text: diferenciaResponse?.data?.mensaje || "Ocurrio un error de conexion",
              severity: "warning",
            });
            setShowMessage(true);
          }
        } else {
          setMessage({
            text: <ErrorComponent errors={removedPersons} persons={persons} />,
            severity: "warning",
          });
          setShowMessage(true);
        }
      };

      const getNumberOfPersons = () => {
        if (!Array.isArray(persons)) {
          return {
            alumnos: 0,
            docentes: 0,
          };
        }

        const numberOfStudents = persons.filter((person) => person?.categoria === 1).length;
        const numberOfTeachers = persons.filter((person) => person?.categoria === 2).length;

        return {
          alumnos: numberOfStudents,
          docentes: numberOfTeachers,
        };
      };

      const body = {
        oficina: data.oficina,
        poliza: data.poliza,
        ramo: data.ramo,
        ...getNumberOfPersons(),
      };

      const checkResponse = await endoso.check605(body);
      const saveWithNewType = async () => await saveEndoso(true);

      if (checkResponse?.data?.codigo === 1) {
        await saveEndoso();
      } else {
        setAlertData({
          message: checkResponse?.data?.mensaje,
          action: saveWithNewType,
        });
      }
    }
    else {
      // guardar archivo
      const enviarSituacionsIndividualmente = async (situacions) => {
        const resultados = [];
        let personsForSave = persons;

        for (const situacion of situacions) {
          try {
            const altaRes = await endoso.carga605(situacion);
            if (altaRes.data.mensaje && altaRes.data.mensaje !== '' && !alertShown) {
              createMessage({
                text: altaRes?.data?.mensaje,
                variant: "info",
                action: true,
              });
              alertShown = true;
            }
            resultados.push({ ...situacion, saved: altaRes?.data?.codigo === 1, changeCategory: altaRes.data.mensaje ? true : false, newCategory: altaRes.data?.categoria });

            if (altaRes?.data?.codigo === 1 && altaRes?.data?.alerta && !alertShown) {
              createMessage({
                text: altaRes.data.alerta,
                variant: "info",
                action: true,
              });
              alertShown = true;
              setShowContinue(true);
            } else if (altaRes?.data.codigo !== 1) {
              createMessage({
                text: altaRes?.data?.mensaje || errorText.default,
                variant: "warning",
              });
            }
          } catch (error) {
            resultados.push({ ...situacion, saved: false });
          }
        }

        return resultados;
      };
      const fileExtension = file.name.split(".").pop();
      if (fileExtension === "xlsx") {
        setBadPersons([]);
        const uploadResponse = await endoso.uploadFile(
          file,
          data.oficina,
          data.ramo,
          data.poliza,
          solicitud
        );
        if (uploadResponse.data?.codigo === "1") {
          const situacions = uploadResponse.data?.altas605;
          const savedSituacions = await enviarSituacionsIndividualmente(situacions);
          const situacionsFinished = savedSituacions?.filter(({ saved }) => saved);
          if (situacionsFinished.length) {
            const diferenciaRes = await endoso.diferencia({
              solicitud: solicitud ?? '',
            });
            const codigo = diferenciaRes?.data?.codigo;
            if (codigo === 1 || codigo === "1") {
              setPersons(
                situacionsFinished.map((person) => ({
                  ...person,
                  categoria: person.categoria === 2 ? 3 : person.categoria,
                  importeActual: diferenciaRes?.data?.importeActual,
                  importeAnterior: diferenciaRes?.data?.importeAnterior,
                  importeDiferencia: diferenciaRes?.data?.importeDiferencia,
                }))
              );

              const clausulaRes = await endoso.clausula({
                solicitud: solicitud ?? '',
              });
              const codigo = clausulaRes?.data?.codigo;
              if (codigo === 1 || codigo === "1") {
                setShowButtons({ ...showButtons, infoTable: true })
              } else

                createMessage({
                  text: clausulaRes?.data?.mensaje || errorText.default,
                  variant: "warning",
                });
            } else
              createMessage({
                text: diferenciaRes?.data?.mensaje || errorText.default,
                variant: "warning",
              });
          } else {
            if (uploadResponse.data?.errores?.length) {
              const errorMessage = uploadResponse?.data?.errores[0]?.mensaje ?? "";
              createMessage({
                text: `No se pudieron agregar todas las personas, por favor verifique que los datos sean correctos: ${errorMessage}`,
                variant: "warning",
              });
            }
          };
        } else {
          if (uploadResponse.data?.errores?.length) {
            const errorMessage = uploadResponse?.data?.errores[0]?.mensaje ?? "";
            createMessage({
              text: `No se pudieron agregar todas las personas, por favor verifique que los datos sean correctos: ${errorMessage}`,
              variant: "warning",
            });
          }
        }
      } else {
        createMessage({
          text: errorText.invalidType,
          variant: "warning",
        });
      }

    }

  }

  return { rulesByTwelve }
};
